// Heartbeat API between Sentio CCU and Wavin Cloud
//
// HTTP POST /heartbeat HeartbeatRequest/HeartbeatResponse
// HTTP POST /log LogRequest/LogResponse
// HTTP POST /package PackageRequest/PackageResponse
//

// @generated by protoc-gen-es v2.0.0 with parameter "target=ts"
// @generated from file wavin/sentio/v1/heartbeat.proto (package wavin.sentio.v1, syntax proto3)
/* eslint-disable */

import type { GenEnum, GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { enumDesc, fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_buf_validate_validate } from "../../../buf/validate/validate_pb";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file wavin/sentio/v1/heartbeat.proto.
 */
export const file_wavin_sentio_v1_heartbeat: GenFile = /*@__PURE__*/
  fileDesc("Ch93YXZpbi9zZW50aW8vdjEvaGVhcnRiZWF0LnByb3RvEg93YXZpbi5zZW50aW8udjEiXAoQSGVhcnRiZWF0UmVxdWVzdBIzCg1icm9rZXJfdmFsdWVzGAEgAygLMhwud2F2aW4uc2VudGlvLnYxLkJyb2tlclZhbHVlEhMKC2Z1bGxfY29uZmlnGAIgASgIIl0KEUhlYXJ0YmVhdFJlc3BvbnNlEjMKDWJyb2tlcl92YWx1ZXMYASADKAsyHC53YXZpbi5zZW50aW8udjEuQnJva2VyVmFsdWUSEwoLZnVsbF9jb25maWcYAiABKAgi+QQKC0Jyb2tlclZhbHVlEgsKA29pZBgBIAEoDRILCgN2aWQYAiABKA0SEQoJdGltZXN0YW1wGAMgASgNEhcKD3RpbWVzdGFtcF9uYW5vcxgSIAEoDRINCgV2YWxpZBgEIAEoCBIkCgZ2YWxfZDEYBSABKAVCErpIDxoNGH8ogP//////////AUgAEiYKBnZhbF9kMhgGIAEoBUIUukgRGg8Y//8BKICA/v///////wFIABIoCgZ2YWxfZDQYByABKAVCFrpIExoRGP////8HKICAgID4/////wFIABIaCgZ2YWxfdTEYCCABKA1CCLpIBSoDGP8BSAASGwoGdmFsX3UyGAkgASgNQgm6SAYqBBj//wNIABIdCgZ2YWxfdTQYCiABKA1CC7pICCoGGP////8PSAASHAoIdmFsX3RleHQYCyABKAlCCLpIBXIDGIACSAASHAoIdmFsX2RhdGEYDCABKAxCCLpIBXoDGIACSAASKwoLdmFsX2QyX2ZwMTAYDSABKAVCFLpIERoPGP//ASiAgP7///////8BSAASLAoMdmFsX2QyX2ZwMTAwGA4gASgFQhS6SBEaDxj//wEogID+////////AUgAEiAKC3ZhbF91Ml9mcDEwGA8gASgNQgm6SAYqBBj//wNIABIhCgx2YWxfdTJfZnAxMDAYECABKA1CCbpIBioEGP//A0gAEicKE3ZhbF9zZXJ2aWNlX2NvbW1hbmQYESABKAxCCLpIBXoDGIAESAASFgoOdGltZXN0YW1wX3NlbnQYEyABKA0SHAoUdGltZXN0YW1wX3NlbnRfbmFub3MYFCABKA1CCgoIdmFyaWFibGUilQEKCkxvZ1JlcXVlc3QSLgoEdHlwZRgBIAEoDjIgLndhdmluLnNlbnRpby52MS5Mb2dSZXF1ZXN0LlR5cGUSFAoMdGV4dF9wYXlsb2FkGAIgASgJIkEKBFR5cGUSFAoQVFlQRV9VTlNQRUNJRklFRBAAEg8KC1RZUEVfTE9PR0VSEAESEgoOVFlQRV9URUxFTUVUUlkQAiINCgtMb2dSZXNwb25zZSJJCg5QYWNrYWdlUmVxdWVzdBIPCgd2ZXJzaW9uGAEgASgJEhMKC3JhbmdlX3N0YXJ0GAIgASgNEhEKCXJhbmdlX2VuZBgDIAEoDSKGAQoPUGFja2FnZVJlc3BvbnNlEhQKDGNvbnRlbnRfc2l6ZRgBIAEoDRIWCg5jb250ZW50X2xlbmd0aBgCIAEoDRIbChNjb250ZW50X3JhbmdlX3N0YXJ0GAMgASgNEhkKEWNvbnRlbnRfcmFuZ2VfZW5kGAQgASgNEg0KBXNsaWNlGAUgASgMQqsBChNjb20ud2F2aW4uc2VudGlvLnYxQg5IZWFydGJlYXRQcm90b1ABWiZ3YXZpbi5pby9nZW4vZ28vd2F2aW4vc2VudGlvL3YxO3NlbnRpb6ICA1dTWKoCD1dhdmluLlNlbnRpby5WMcoCD1dhdmluXFNlbnRpb1xWMeICG1dhdmluXFNlbnRpb1xWMVxHUEJNZXRhZGF0YeoCEVdhdmluOjpTZW50aW86OlYxYgZwcm90bzM", [file_buf_validate_validate]);

/**
 * HeartbeatRequest is initiated by CCU to cloud to handle long poll loop
 *
 * @generated from message wavin.sentio.v1.HeartbeatRequest
 */
export type HeartbeatRequest = Message<"wavin.sentio.v1.HeartbeatRequest"> & {
  /**
   * Broker values must be in top-level message for performance reasons with CCU)
   * Config changes from CCU to Cloud
   *
   * @generated from field: repeated wavin.sentio.v1.BrokerValue broker_values = 1;
   */
  brokerValues: BrokerValue[];

  /**
   * CCU is sending full config
   *
   * @generated from field: bool full_config = 2;
   */
  fullConfig: boolean;
};

/**
 * Describes the message wavin.sentio.v1.HeartbeatRequest.
 * Use `create(HeartbeatRequestSchema)` to create a new message.
 */
export const HeartbeatRequestSchema: GenMessage<HeartbeatRequest> = /*@__PURE__*/
  messageDesc(file_wavin_sentio_v1_heartbeat, 0);

/**
 * @generated from message wavin.sentio.v1.HeartbeatResponse
 */
export type HeartbeatResponse = Message<"wavin.sentio.v1.HeartbeatResponse"> & {
  /**
   * Config changes to be applied at CCU from Cloud, contains only changed broker values
   *
   * @generated from field: repeated wavin.sentio.v1.BrokerValue broker_values = 1;
   */
  brokerValues: BrokerValue[];

  /**
   * Cloud requests CCU to send the full configuration.
   *
   * @generated from field: bool full_config = 2;
   */
  fullConfig: boolean;
};

/**
 * Describes the message wavin.sentio.v1.HeartbeatResponse.
 * Use `create(HeartbeatResponseSchema)` to create a new message.
 */
export const HeartbeatResponseSchema: GenMessage<HeartbeatResponse> = /*@__PURE__*/
  messageDesc(file_wavin_sentio_v1_heartbeat, 1);

/**
 * Roxi Broker Value type object
 *
 * @generated from message wavin.sentio.v1.BrokerValue
 */
export type BrokerValue = Message<"wavin.sentio.v1.BrokerValue"> & {
  /**
   * object ID of value,
   *
   * @generated from field: uint32 oid = 1;
   */
  oid: number;

  /**
   * Value ID
   *
   * @generated from field: uint32 vid = 2;
   */
  vid: number;

  /**
   * Timestamp in unix time (seconds) of last change (used for decide which value is last when communication delay occurs)
   *
   * @generated from field: uint32 timestamp = 3;
   */
  timestamp: number;

  /**
   * Nanoseconds part of timestamp if available (note: CCU has only second precision so nanos will be zero)
   *
   * @generated from field: uint32 timestamp_nanos = 18;
   */
  timestampNanos: number;

  /**
   * False: value is invalid, True: value is valid
   *
   * @generated from field: bool valid = 4;
   */
  valid: boolean;

  /**
   * Variable is the value
   *
   * @generated from oneof wavin.sentio.v1.BrokerValue.variable
   */
  variable: {
    /**
     * 1 byte signed integer
     *
     * @generated from field: int32 val_d1 = 5;
     */
    value: number;
    case: "valD1";
  } | {
    /**
     * 2 bytes signed integer
     *
     * @generated from field: int32 val_d2 = 6;
     */
    value: number;
    case: "valD2";
  } | {
    /**
     * 4 bytes signed integer
     *
     * @generated from field: int32 val_d4 = 7;
     */
    value: number;
    case: "valD4";
  } | {
    /**
     * 1 byte unsigned integer
     *
     * @generated from field: uint32 val_u1 = 8;
     */
    value: number;
    case: "valU1";
  } | {
    /**
     * 2 bytes unsigned integer
     *
     * @generated from field: uint32 val_u2 = 9;
     */
    value: number;
    case: "valU2";
  } | {
    /**
     * 4 bytes unsigned integer
     *
     * @generated from field: uint32 val_u4 = 10;
     */
    value: number;
    case: "valU4";
  } | {
    /**
     * max 256 ASCII value
     *
     * @generated from field: string val_text = 11;
     */
    value: string;
    case: "valText";
  } | {
    /**
     * max 256 bytes of data
     *
     * @generated from field: bytes val_data = 12;
     */
    value: Uint8Array;
    case: "valData";
  } | {
    /**
     * 2 bytes signed decimal fixed point number. (value * 10) (-1.1 -> -11)
     *
     * @generated from field: int32 val_d2_fp10 = 13;
     */
    value: number;
    case: "valD2Fp10";
  } | {
    /**
     * 2 bytes signed fixed point number. (value * 100) (-1.11 -> -111)
     *
     * @generated from field: int32 val_d2_fp100 = 14;
     */
    value: number;
    case: "valD2Fp100";
  } | {
    /**
     * 2 bytes unsigned fixed point number. (value * 10) (1.1 -> 11)
     *
     * @generated from field: uint32 val_u2_fp10 = 15;
     */
    value: number;
    case: "valU2Fp10";
  } | {
    /**
     * 2 bytes unsigned fixed point number. (value * 100) (1.11 -> 111)
     *
     * @generated from field: uint32 val_u2_fp100 = 16;
     */
    value: number;
    case: "valU2Fp100";
  } | {
    /**
     * max 512 bytes of data
     *
     * @generated from field: bytes val_service_command = 17;
     */
    value: Uint8Array;
    case: "valServiceCommand";
  } | { case: undefined; value?: undefined };

  /**
   * Timestamp sent to CCU in unix time (seconds)
   *
   * @generated from field: uint32 timestamp_sent = 19;
   */
  timestampSent: number;

  /**
   * Timestamp sent to CCU nanoseconds part of timestamp_sent
   *
   * @generated from field: uint32 timestamp_sent_nanos = 20;
   */
  timestampSentNanos: number;
};

/**
 * Describes the message wavin.sentio.v1.BrokerValue.
 * Use `create(BrokerValueSchema)` to create a new message.
 */
export const BrokerValueSchema: GenMessage<BrokerValue> = /*@__PURE__*/
  messageDesc(file_wavin_sentio_v1_heartbeat, 2);

/**
 * LogRequest is initiated by CCU to cloud to send a text log
 * Device information from originating device will be automatically related.
 *
 * @generated from message wavin.sentio.v1.LogRequest
 */
export type LogRequest = Message<"wavin.sentio.v1.LogRequest"> & {
  /**
   * @generated from field: wavin.sentio.v1.LogRequest.Type type = 1;
   */
  type: LogRequest_Type;

  /**
   * Payload represented as a Unicode string (UTF-8).
   *
   * @generated from field: string text_payload = 2;
   */
  textPayload: string;
};

/**
 * Describes the message wavin.sentio.v1.LogRequest.
 * Use `create(LogRequestSchema)` to create a new message.
 */
export const LogRequestSchema: GenMessage<LogRequest> = /*@__PURE__*/
  messageDesc(file_wavin_sentio_v1_heartbeat, 3);

/**
 * Type of log entry to determine post-processing step
 *
 * @generated from enum wavin.sentio.v1.LogRequest.Type
 */
export enum LogRequest_Type {
  /**
   * @generated from enum value: TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: TYPE_LOOGER = 1;
   */
  LOOGER = 1,

  /**
   * @generated from enum value: TYPE_TELEMETRY = 2;
   */
  TELEMETRY = 2,
}

/**
 * Describes the enum wavin.sentio.v1.LogRequest.Type.
 */
export const LogRequest_TypeSchema: GenEnum<LogRequest_Type> = /*@__PURE__*/
  enumDesc(file_wavin_sentio_v1_heartbeat, 3, 0);

/**
 * Empty response
 *
 * @generated from message wavin.sentio.v1.LogResponse
 */
export type LogResponse = Message<"wavin.sentio.v1.LogResponse"> & {
};

/**
 * Describes the message wavin.sentio.v1.LogResponse.
 * Use `create(LogResponseSchema)` to create a new message.
 */
export const LogResponseSchema: GenMessage<LogResponse> = /*@__PURE__*/
  messageDesc(file_wavin_sentio_v1_heartbeat, 4);

/**
 * PackageRequest is request initiated by CCU to cloud to download firmware package (CCU determines size of byte slice)
 * The version is determined by broker value update_ctrl.download_version and the state is stored in update_ctrl.download_state (IDLE, DOWNLOADING, FAILED)
 *
 * 400 Bad request: range out of bounds
 * 404 Not found: version not found
 *
 * @generated from message wavin.sentio.v1.PackageRequest
 */
export type PackageRequest = Message<"wavin.sentio.v1.PackageRequest"> & {
  /**
   * semantic version, i.e. 15.120.1
   *
   * @generated from field: string version = 1;
   */
  version: string;

  /**
   * Start index of byte range to retrieve (zero-indexed)
   *
   * @generated from field: uint32 range_start = 2;
   */
  rangeStart: number;

  /**
   * End index of byte range to retrieve (inclusive), if zero until end is retrieved.
   *
   * @generated from field: uint32 range_end = 3;
   */
  rangeEnd: number;
};

/**
 * Describes the message wavin.sentio.v1.PackageRequest.
 * Use `create(PackageRequestSchema)` to create a new message.
 */
export const PackageRequestSchema: GenMessage<PackageRequest> = /*@__PURE__*/
  messageDesc(file_wavin_sentio_v1_heartbeat, 5);

/**
 * @generated from message wavin.sentio.v1.PackageResponse
 */
export type PackageResponse = Message<"wavin.sentio.v1.PackageResponse"> & {
  /**
   * Content size of full package in bytes
   *
   * @generated from field: uint32 content_size = 1;
   */
  contentSize: number;

  /**
   * Content length of requested byte slice
   *
   * @generated from field: uint32 content_length = 2;
   */
  contentLength: number;

  /**
   * Start index of requested byte range (zero-indexed)
   *
   * @generated from field: uint32 content_range_start = 3;
   */
  contentRangeStart: number;

  /**
   * End index of requested byte range (inclusive)
   *
   * @generated from field: uint32 content_range_end = 4;
   */
  contentRangeEnd: number;

  /**
   * Slice of requested bytes
   *
   * @generated from field: bytes slice = 5;
   */
  slice: Uint8Array;
};

/**
 * Describes the message wavin.sentio.v1.PackageResponse.
 * Use `create(PackageResponseSchema)` to create a new message.
 */
export const PackageResponseSchema: GenMessage<PackageResponse> = /*@__PURE__*/
  messageDesc(file_wavin_sentio_v1_heartbeat, 6);


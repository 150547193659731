import { Timestamp, timestampDate } from '@bufbuild/protobuf/wkt';

import dayjs from 'dayjs';

export function formatTimestamp(
  timestamp?: Timestamp,
  { includeTime = true, includeMilliseconds = false } = {},
) {
  if (!timestamp) return '';

  const date = timestampDate(timestamp);

  let formatString = 'YYYY-MM-DD';

  if (includeTime) formatString += ' HH:mm:ss';

  if (includeMilliseconds) formatString += '.SSS';

  return dayjs(date).format(formatString);
}

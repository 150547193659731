// @generated by protoc-gen-es v2.0.0 with parameter "target=ts"
// @generated from file wavin/ignite/v1/user.proto (package wavin.ignite.v1, syntax proto3)
/* eslint-disable */

import type { GenEnum, GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { enumDesc, fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_buf_validate_validate } from "../../../buf/validate/validate_pb";
import type { Timestamp } from "@bufbuild/protobuf/wkt";
import { file_google_protobuf_timestamp } from "@bufbuild/protobuf/wkt";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file wavin/ignite/v1/user.proto.
 */
export const file_wavin_ignite_v1_user: GenFile = /*@__PURE__*/
  fileDesc("Chp3YXZpbi9pZ25pdGUvdjEvdXNlci5wcm90bxIPd2F2aW4uaWduaXRlLnYxIt4FCgRVc2VyEh4KBG5hbWUYASABKAlCELpIDcgBAXIIOgZ1c2Vycy8SDQoFZW1haWwYAiABKAkSYQoNbGFuZ3VhZ2VfY29kZRgDIAEoCUJFukhCckBSAmNzUgJkYVICZGVSAmVuUgJlc1ICZmlSAmZyUgJodVICaXRSAmx0UgJsdlICbmxSAm5vUgJwbFICc3ZSAnRySACIAQESIwoWdGVybXNfYWNjZXB0ZWRfdmVyc2lvbhgEIAEoCUgBiAEBEhkKDGNvbXBhbnlfbmFtZRgFIAEoCUgCiAEBEhcKCmZpcnN0X25hbWUYBiABKAlIA4gBARIWCglsYXN0X25hbWUYByABKAlIBIgBARIdChBzdHJlZXRfYWRkcmVzc18xGAggASgJSAWIAQESHQoQc3RyZWV0X2FkZHJlc3NfMhgJIAEoCUgGiAEBEhUKCHppcF9jb2RlGAogASgJSAeIAQESEQoEY2l0eRgLIAEoCUgIiAEBEhQKB2NvdW50cnkYDCABKAlICYgBARIoCgdkZXZpY2VzGA0gAygLMhcud2F2aW4uaWduaXRlLnYxLkRldmljZRIkCgVyb2xlcxgOIAMoDjIVLndhdmluLmlnbml0ZS52MS5Sb2xlEkEKGGluc3RhbGxlcl9hZ3JlZW1lbnRfdGltZRgPIAEoCzIaLmdvb2dsZS5wcm90b2J1Zi5UaW1lc3RhbXBICogBAUIQCg5fbGFuZ3VhZ2VfY29kZUIZChdfdGVybXNfYWNjZXB0ZWRfdmVyc2lvbkIPCg1fY29tcGFueV9uYW1lQg0KC19maXJzdF9uYW1lQgwKCl9sYXN0X25hbWVCEwoRX3N0cmVldF9hZGRyZXNzXzFCEwoRX3N0cmVldF9hZGRyZXNzXzJCCwoJX3ppcF9jb2RlQgcKBV9jaXR5QgoKCF9jb3VudHJ5QhsKGV9pbnN0YWxsZXJfYWdyZWVtZW50X3RpbWUiLgoGRGV2aWNlEgoKAmlkGAEgASgJEhgKEHJlZ2lzdHJhdGlvbl9rZXkYAiABKAkqrwEKBFJvbGUSFAoQUk9MRV9VTlNQRUNJRklFRBAAEhIKDlJPTEVfSUFNX0FETUlOEAESGAoUUk9MRV9URUxFTUVUUllfQURNSU4QAhIWChJST0xFX0NPTkZJR19FRElUT1IQAxIXChNST0xFX0ZJUk1XQVJFX0FETUlOEAQSGAoUUk9MRV9GSVJNV0FSRV9FRElUT1IQBRIYChRST0xFX0ZJUk1XQVJFX1ZJRVdFUhAGQn4KE2NvbS53YXZpbi5pZ25pdGUudjFCCVVzZXJQcm90b1ABogIDV0lYqgIPV2F2aW4uSWduaXRlLlYxygIPV2F2aW5cSWduaXRlXFYx4gIbV2F2aW5cSWduaXRlXFYxXEdQQk1ldGFkYXRh6gIRV2F2aW46Oklnbml0ZTo6VjFiBnByb3RvMw", [file_buf_validate_validate, file_google_protobuf_timestamp]);

/**
 * @generated from message wavin.ignite.v1.User
 */
export type User = Message<"wavin.ignite.v1.User"> & {
  /**
   * Resource name of users, format: users/{usersID}
   *
   * @generated from field: string name = 1;
   */
  name: string;

  /**
   * @generated from field: string email = 2;
   */
  email: string;

  /**
   * @generated from field: optional string language_code = 3;
   */
  languageCode?: string;

  /**
   * @generated from field: optional string terms_accepted_version = 4;
   */
  termsAcceptedVersion?: string;

  /**
   * @generated from field: optional string company_name = 5;
   */
  companyName?: string;

  /**
   * @generated from field: optional string first_name = 6;
   */
  firstName?: string;

  /**
   * @generated from field: optional string last_name = 7;
   */
  lastName?: string;

  /**
   * @generated from field: optional string street_address_1 = 8;
   */
  streetAddress1?: string;

  /**
   * @generated from field: optional string street_address_2 = 9;
   */
  streetAddress2?: string;

  /**
   * @generated from field: optional string zip_code = 10;
   */
  zipCode?: string;

  /**
   * @generated from field: optional string city = 11;
   */
  city?: string;

  /**
   * @generated from field: optional string country = 12;
   */
  country?: string;

  /**
   * @generated from field: repeated wavin.ignite.v1.Device devices = 13;
   */
  devices: Device[];

  /**
   * @generated from field: repeated wavin.ignite.v1.Role roles = 14;
   */
  roles: Role[];

  /**
   * @generated from field: optional google.protobuf.Timestamp installer_agreement_time = 15;
   */
  installerAgreementTime?: Timestamp;
};

/**
 * Describes the message wavin.ignite.v1.User.
 * Use `create(UserSchema)` to create a new message.
 */
export const UserSchema: GenMessage<User> = /*@__PURE__*/
  messageDesc(file_wavin_ignite_v1_user, 0);

/**
 * @generated from message wavin.ignite.v1.Device
 */
export type Device = Message<"wavin.ignite.v1.Device"> & {
  /**
   * uniquely identifies an ios or android device
   *
   * @generated from field: string id = 1;
   */
  id: string;

  /**
   * registration key that is used to send notifications to a device
   *
   * @generated from field: string registration_key = 2;
   */
  registrationKey: string;
};

/**
 * Describes the message wavin.ignite.v1.Device.
 * Use `create(DeviceSchema)` to create a new message.
 */
export const DeviceSchema: GenMessage<Device> = /*@__PURE__*/
  messageDesc(file_wavin_ignite_v1_user, 1);

/**
 * @generated from enum wavin.ignite.v1.Role
 */
export enum Role {
  /**
   * @generated from enum value: ROLE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * View & assign roles of users.
   *
   * @generated from enum value: ROLE_IAM_ADMIN = 1;
   */
  IAM_ADMIN = 1,

  /**
   * View telemetry data for all devices.
   *
   * @generated from enum value: ROLE_TELEMETRY_ADMIN = 2;
   */
  TELEMETRY_ADMIN = 2,

  /**
   * View & edit configuration for claimed devices.
   *
   * @generated from enum value: ROLE_CONFIG_EDITOR = 3;
   */
  CONFIG_EDITOR = 3,

  /**
   * Release firmware version GA for all devices.
   *
   * @generated from enum value: ROLE_FIRMWARE_ADMIN = 4;
   */
  FIRMWARE_ADMIN = 4,

  /**
   * List & download GA & Beta firmware releases. Upload firmware. Release to claimed devices.
   *
   * @generated from enum value: ROLE_FIRMWARE_EDITOR = 5;
   */
  FIRMWARE_EDITOR = 5,

  /**
   * List & download GA firmware releases.
   *
   * @generated from enum value: ROLE_FIRMWARE_VIEWER = 6;
   */
  FIRMWARE_VIEWER = 6,
}

/**
 * Describes the enum wavin.ignite.v1.Role.
 */
export const RoleSchema: GenEnum<Role> = /*@__PURE__*/
  enumDesc(file_wavin_ignite_v1_user, 0);


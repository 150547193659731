import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePrevious } from '~/pages/layout/hooks/usePrevious';
import { useUser } from '~/shared/hooks/useUser';

export function useSetLanguage() {
  const { i18n } = useTranslation();
  const user = useUser();

  const languageCode = user?.languageCode;

  const previousLanguageCode = usePrevious(languageCode);

  useEffect(() => {
    if (languageCode && languageCode !== previousLanguageCode) {
      i18n.changeLanguage(languageCode);
    }
  }, [i18n, languageCode, previousLanguageCode]);
}

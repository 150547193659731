// @generated by protoc-gen-es v2.0.0 with parameter "target=ts"
// @generated from file wavin/blaze/v1/device_platform_status.proto (package wavin.blaze.v1, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_buf_validate_validate } from "../../../buf/validate/validate_pb";
import type { Device_FirmwareUpdateStatus, Device_Platform, Device_Status } from "./device_pb";
import { file_wavin_blaze_v1_device } from "./device_pb";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file wavin/blaze/v1/device_platform_status.proto.
 */
export const file_wavin_blaze_v1_device_platform_status: GenFile = /*@__PURE__*/
  fileDesc("Cit3YXZpbi9ibGF6ZS92MS9kZXZpY2VfcGxhdGZvcm1fc3RhdHVzLnByb3RvEg53YXZpbi5ibGF6ZS52MSLkAQoURGV2aWNlUGxhdGZvcm1TdGF0dXMSHQoEbmFtZRgBIAEoCUIPukgMcgo6CGRldmljZXMvEi0KBnN0YXR1cxgCIAEoDjIdLndhdmluLmJsYXplLnYxLkRldmljZS5TdGF0dXMSMQoIcGxhdGZvcm0YAyABKA4yHy53YXZpbi5ibGF6ZS52MS5EZXZpY2UuUGxhdGZvcm0SSwoWZmlybXdhcmVfdXBkYXRlX3N0YXR1cxgEIAEoDjIrLndhdmluLmJsYXplLnYxLkRldmljZS5GaXJtd2FyZVVwZGF0ZVN0YXR1c0KJAQoSY29tLndhdmluLmJsYXplLnYxQhlEZXZpY2VQbGF0Zm9ybVN0YXR1c1Byb3RvUAGiAgNXQliqAg5XYXZpbi5CbGF6ZS5WMcoCDldhdmluXEJsYXplXFYx4gIaV2F2aW5cQmxhemVcVjFcR1BCTWV0YWRhdGHqAhBXYXZpbjo6QmxhemU6OlYxYgZwcm90bzM", [file_buf_validate_validate, file_wavin_blaze_v1_device]);

/**
 * The device platform status returns device platform and connectivity status
 *
 * @generated from message wavin.blaze.v1.DevicePlatformStatus
 */
export type DevicePlatformStatus = Message<"wavin.blaze.v1.DevicePlatformStatus"> & {
  /**
   * Device resource name, format: devices/{device}
   *
   * @generated from field: string name = 1;
   */
  name: string;

  /**
   * @generated from field: wavin.blaze.v1.Device.Status status = 2;
   */
  status: Device_Status;

  /**
   * @generated from field: wavin.blaze.v1.Device.Platform platform = 3;
   */
  platform: Device_Platform;

  /**
   * @generated from field: wavin.blaze.v1.Device.FirmwareUpdateStatus firmware_update_status = 4;
   */
  firmwareUpdateStatus: Device_FirmwareUpdateStatus;
};

/**
 * Describes the message wavin.blaze.v1.DevicePlatformStatus.
 * Use `create(DevicePlatformStatusSchema)` to create a new message.
 */
export const DevicePlatformStatusSchema: GenMessage<DevicePlatformStatus> = /*@__PURE__*/
  messageDesc(file_wavin_blaze_v1_device_platform_status, 0);


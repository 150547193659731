import { QueryClient } from '@tanstack/react-query';
import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import { getAuthUser } from '~/shared/auth/auth-utils';
import { createRoutes } from '~/routes';

export const installerCheckPageLoader =
  (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs) => {
    const user = await getAuthUser(queryClient, request);

    if (user.installerAgreementTime) {
      return redirect(createRoutes.Settings);
    }

    return null;
  };

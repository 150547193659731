import { timestampNow } from '@bufbuild/protobuf/wkt';
import { QueryClient } from '@tanstack/react-query';
import { ActionFunction } from 'react-router-dom';
import { ValidationI18nError, yup } from '~/services/validation';
import { getUser, patchUser } from '~/shared/api/user.api';
import { userQuery } from '~/shared/api/user.queries';
import { UserModel } from '~/shared/models/user/UserModel';
import { I18nKeys } from '~/types/react-i18next';

export type InstallerCheckAction = {
  validationError?: ValidationI18nError<InstallerCheckFormData>;
  successFullyRequestedAccess: boolean;
};

const InstallerCheckFormSchema = yup.object({
  companyName: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  streetAddress: yup.string().required(),
  zipCode: yup.string().required(),
  city: yup.string().required(),
  country: yup.string().required(),
});
export type InstallerCheckFormData = yup.InferType<
  typeof InstallerCheckFormSchema
>;

export const installerCheckPageAction =
  (queryClient: QueryClient): ActionFunction =>
  async ({ request }): Promise<InstallerCheckAction> => {
    const formData = await request.formData();
    const {
      city,
      companyName,
      country,
      firstName,
      lastName,
      streetAddress,
      zipCode,
    } = Object.fromEntries(formData) as InstallerCheckFormData;

    try {
      await InstallerCheckFormSchema.validate({
        companyName,
        firstName,
        lastName,
        streetAddress,
        zipCode,
        city,
        country,
      });
    } catch (error) {
      const validateError = error as yup.ValidationError;
      const errorKeys = validateError.errors as I18nKeys[];
      if (!validateError.path) {
        throw Error('Path is required');
      }
      return {
        validationError: new ValidationI18nError(
          errorKeys[0],
          validateError.path as keyof InstallerCheckFormData,
        ),
        successFullyRequestedAccess: false,
      };
    }

    const user = await getUser();
    const result = await patchUser({
      user: {
        installerAgreementTime: timestampNow(),
        city,
        companyName,
        country,
        firstName,
        lastName,
        name: user.name,
        streetAddress1: streetAddress,
        zipCode,
      },
    });

    queryClient.setQueryData(userQuery().queryKey, new UserModel(result));

    return {
      successFullyRequestedAccess: true,
    };
  };

import { SvgIcon, SvgIconProps } from '@mui/material';
import {
  PiArrowRight,
  PiCaretDown,
  PiSignOut,
  PiX,
  PiBuildings,
  PiCheckBold,
  PiXBold,
} from 'react-icons/pi';
import { IoIosClose } from 'react-icons/io';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import {
  MdMenu,
  MdOutlineEdit,
  MdDelete,
  MdVisibility,
  MdVisibilityOff,
} from 'react-icons/md';
import { TfiViewList } from 'react-icons/tfi';
import { GearFine, Question, Users } from '@phosphor-icons/react';
import { FaArrowUpRightFromSquare } from 'react-icons/fa6';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { FaUserPlus } from 'react-icons/fa';
import { BiMicrochip } from 'react-icons/bi';

export function ArrowRightIcon(props: SvgIconProps) {
  return <SvgIcon component={PiArrowRight} inheritViewBox {...props} />;
}

export function InformationCircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon inheritViewBox {...props} viewBox="0 0 22 21">
      <path d="M21.742 10.478c0 5.755-4.666 10.421-10.421 10.421C5.566 20.899.9 16.233.9 10.478.9 4.723 5.566.057 11.321.057c5.755 0 10.421 4.666 10.421 10.42Z" />
      <path
        fill="#fff"
        d="M10.867 8.457c0-.247.059-.414.177-.5.119-.097.285-.146.5-.146.216 0 .383.049.5.146.13.086.194.253.194.5v7.035c0 .248-.064.42-.193.517-.118.085-.285.129-.5.129-.216 0-.382-.044-.5-.13-.119-.096-.178-.268-.178-.516V8.457Zm-.194-2.872c0-.28.08-.495.242-.646a.886.886 0 0 1 .63-.242.83.83 0 0 1 .613.242c.172.15.258.366.258.646s-.086.5-.258.661a.863.863 0 0 1-.613.226.922.922 0 0 1-.63-.226c-.161-.161-.242-.382-.242-.661Z"
      />
    </SvgIcon>
  );
}

export function VisibilityIcon(props: SvgIconProps) {
  return <SvgIcon component={MdVisibility} inheritViewBox {...props} />;
}

export function VisibilityOffIcon(props: SvgIconProps) {
  return <SvgIcon component={MdVisibilityOff} inheritViewBox {...props} />;
}

export function ChevronDownIcon(props: SvgIconProps) {
  return <SvgIcon component={PiCaretDown} inheritViewBox {...props} />;
}

export function ArrowRightCircleFilledIcon(props: SvgIconProps) {
  return (
    <SvgIcon component={BsFillArrowRightCircleFill} inheritViewBox {...props} />
  );
}

export function CloseIcon(props: SvgIconProps) {
  return <SvgIcon component={PiX} inheritViewBox {...props} />;
}

export function CheckmarkIcon(props: SvgIconProps) {
  return <SvgIcon component={PiCheckBold} inheritViewBox {...props} />;
}

export function XIcon(props: SvgIconProps) {
  return <SvgIcon component={PiXBold} inheritViewBox {...props} />;
}

export function MenuIcon(props: SvgIconProps) {
  return <SvgIcon component={MdMenu} inheritViewBox {...props} />;
}

export function SignOutIcon(props: SvgIconProps) {
  return <SvgIcon component={PiSignOut} inheritViewBox {...props} />;
}

export function DevicesListIcon(props: SvgIconProps) {
  return <SvgIcon component={TfiViewList} inheritViewBox {...props} />;
}

export function DeviceIcon(props: SvgIconProps) {
  return <SvgIcon component={PiBuildings} inheritViewBox {...props} />;
}

export function UploadIcon(props: SvgIconProps) {
  return <SvgIcon component={AiOutlineCloudUpload} inheritViewBox {...props} />;
}

export function FirmwareListIcon(props: SvgIconProps) {
  return <SvgIcon component={BiMicrochip} inheritViewBox {...props} />;
}

export function IosCloseIcon(props: SvgIconProps) {
  return <SvgIcon component={IoIosClose} inheritViewBox {...props} />;
}

export function EditIcon(props: SvgIconProps) {
  return <SvgIcon component={MdOutlineEdit} inheritViewBox {...props} />;
}

export function UsersIcon(props: SvgIconProps) {
  return <SvgIcon component={Users} inheritViewBox {...props} />;
}

export function GearIcon(props: SvgIconProps) {
  return <SvgIcon component={GearFine} inheritViewBox {...props} />;
}

export function DeleteIcon(props: SvgIconProps) {
  return <SvgIcon component={MdDelete} inheritViewBox {...props} />;
}

export function SupportIcon(props: SvgIconProps) {
  return <SvgIcon component={Question} inheritViewBox {...props} />;
}

export function UserPlusIcon(props: SvgIconProps) {
  return <SvgIcon component={FaUserPlus} inheritViewBox {...props} />;
}

export function ExternalLinkIcon(props: SvgIconProps) {
  return (
    <SvgIcon component={FaArrowUpRightFromSquare} inheritViewBox {...props} />
  );
}

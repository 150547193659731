// @generated by protoc-gen-es v2.0.0 with parameter "target=ts"
// @generated from file wavin/blaze/v1/device_claim.proto (package wavin.blaze.v1, syntax proto3)
/* eslint-disable */

import type { GenEnum, GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { enumDesc, fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import type { Timestamp } from "@bufbuild/protobuf/wkt";
import { file_google_protobuf_timestamp } from "@bufbuild/protobuf/wkt";
import type { User } from "./user_pb";
import { file_wavin_blaze_v1_user } from "./user_pb";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file wavin/blaze/v1/device_claim.proto.
 */
export const file_wavin_blaze_v1_device_claim: GenFile = /*@__PURE__*/
  fileDesc("CiF3YXZpbi9ibGF6ZS92MS9kZXZpY2VfY2xhaW0ucHJvdG8SDndhdmluLmJsYXplLnYxItkDCgtEZXZpY2VDbGFpbRIMCgRuYW1lGAEgASgJEi8KC2NyZWF0ZV90aW1lGAIgASgLMhouZ29vZ2xlLnByb3RvYnVmLlRpbWVzdGFtcBIvCgtkZWxldGVfdGltZRgDIAEoCzIaLmdvb2dsZS5wcm90b2J1Zi5UaW1lc3RhbXASIgoEdXNlchgEIAEoCzIULndhdmluLmJsYXplLnYxLlVzZXISLgoEdHlwZRgFIAEoDjIgLndhdmluLmJsYXplLnYxLkRldmljZUNsYWltLlR5cGUSMgoGbWV0aG9kGAYgASgOMiIud2F2aW4uYmxhemUudjEuRGV2aWNlQ2xhaW0uTWV0aG9kIlIKBFR5cGUSFAoQVFlQRV9VTlNQRUNJRklFRBAAEhkKFVRZUEVfUEVSTUFORU5UX0FDQ0VTUxABEhkKFVRZUEVfVEVNUE9SQVJZX0FDQ0VTUxACIn4KBk1ldGhvZBIiCh5NRVRIT0RfQ0NVX0JVVFRPTl9DT05GSVJNQVRJT04QABIaChZNRVRIT0RfQ0NVX0FDQ0VTU19DT0RFEAESFQoRTUVUSE9EX0FDQ0VTU19LRVkQAhIdChlNRVRIT0RfSU5TVEFMTEVSX1BBU1NXT1JEEANCgAEKEmNvbS53YXZpbi5ibGF6ZS52MUIQRGV2aWNlQ2xhaW1Qcm90b1ABogIDV0JYqgIOV2F2aW4uQmxhemUuVjHKAg5XYXZpblxCbGF6ZVxWMeICGldhdmluXEJsYXplXFYxXEdQQk1ldGFkYXRh6gIQV2F2aW46OkJsYXplOjpWMWIGcHJvdG8z", [file_google_protobuf_timestamp, file_wavin_blaze_v1_user]);

/**
 * A device claim is a permission to access a device by a user
 *
 * @generated from message wavin.blaze.v1.DeviceClaim
 */
export type DeviceClaim = Message<"wavin.blaze.v1.DeviceClaim"> & {
  /**
   * DeviceClaim resource name, format: devices/{device}/claims/{claim}
   *
   * @generated from field: string name = 1;
   */
  name: string;

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 2;
   */
  createTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp delete_time = 3;
   */
  deleteTime?: Timestamp;

  /**
   * User who holds the claim
   *
   * @generated from field: wavin.blaze.v1.User user = 4;
   */
  user?: User;

  /**
   * @generated from field: wavin.blaze.v1.DeviceClaim.Type type = 5;
   */
  type: DeviceClaim_Type;

  /**
   * @generated from field: wavin.blaze.v1.DeviceClaim.Method method = 6;
   */
  method: DeviceClaim_Method;
};

/**
 * Describes the message wavin.blaze.v1.DeviceClaim.
 * Use `create(DeviceClaimSchema)` to create a new message.
 */
export const DeviceClaimSchema: GenMessage<DeviceClaim> = /*@__PURE__*/
  messageDesc(file_wavin_blaze_v1_device_claim, 0);

/**
 * @generated from enum wavin.blaze.v1.DeviceClaim.Type
 */
export enum DeviceClaim_Type {
  /**
   * @generated from enum value: TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: TYPE_PERMANENT_ACCESS = 1;
   */
  PERMANENT_ACCESS = 1,

  /**
   * For temporary access delete time indicates when claim is deleted
   *
   * @generated from enum value: TYPE_TEMPORARY_ACCESS = 2;
   */
  TEMPORARY_ACCESS = 2,
}

/**
 * Describes the enum wavin.blaze.v1.DeviceClaim.Type.
 */
export const DeviceClaim_TypeSchema: GenEnum<DeviceClaim_Type> = /*@__PURE__*/
  enumDesc(file_wavin_blaze_v1_device_claim, 0, 0);

/**
 * @generated from enum wavin.blaze.v1.DeviceClaim.Method
 */
export enum DeviceClaim_Method {
  /**
   * buf:lint:ignore ENUM_ZERO_VALUE_SUFFIX
   *
   * @generated from enum value: METHOD_CCU_BUTTON_CONFIRMATION = 0;
   */
  CCU_BUTTON_CONFIRMATION = 0,

  /**
   * @generated from enum value: METHOD_CCU_ACCESS_CODE = 1;
   */
  CCU_ACCESS_CODE = 1,

  /**
   * @generated from enum value: METHOD_ACCESS_KEY = 2;
   */
  ACCESS_KEY = 2,

  /**
   * @generated from enum value: METHOD_INSTALLER_PASSWORD = 3;
   */
  INSTALLER_PASSWORD = 3,
}

/**
 * Describes the enum wavin.blaze.v1.DeviceClaim.Method.
 */
export const DeviceClaim_MethodSchema: GenEnum<DeviceClaim_Method> = /*@__PURE__*/
  enumDesc(file_wavin_blaze_v1_device_claim, 0, 1);


import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { useSetLanguage } from '~/pages/layout/hooks/useSetLanguage';

export function AppContent() {
  useSetLanguage();

  return (
    <Suspense>
      <Outlet />
    </Suspense>
  );
}

import { QueryClient } from '@tanstack/react-query';
import { LoaderFunctionArgs } from 'react-router-dom';
import { listUsersQuery } from '~/shared/api/listUsers.queries';
import { checkAuthAndRole } from '~/shared/auth/auth-utils';
import { RoleModel } from '~/shared/models/role/RoleModel';
import { UsersListModel } from '~/shared/models/usersList/UsersListModel';
import { Role } from '~/types/wavin/ignite/v1/user_pb';

export type UsersListPageLoaderData = UsersListModel;

export const usersListPageLoader =
  (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs) => {
    await checkAuthAndRole(queryClient, request, new RoleModel(Role.IAM_ADMIN));

    return await queryClient.ensureQueryData(listUsersQuery);
  };

import { useUser } from '~/shared/hooks/useUser';

export const TERMS_AND_CONDITIONS_VERSION = '1.0.0';

export const hasAcceptedTerms = (termsAcceptedVersion?: string) =>
  termsAcceptedVersion === TERMS_AND_CONDITIONS_VERSION;

export const useUserHasAcceptedTerms = () => {
  const user = useUser();

  if (!user) {
    return false;
  }

  return hasAcceptedTerms(user.termsAcceptedVersion);
};

export function createReleaseVersion({
  buildTime,
  tagName,
  environment,
  shortSha,
}: {
  buildTime?: string;
  tagName?: string;
  environment?: string;
  shortSha?: string;
}): string {
  const releaseVersion = [buildTime, tagName, environment, shortSha]
    .filter(Boolean)
    .join('-');

  return releaseVersion || 'local';
}

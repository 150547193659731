import { QueryClient } from '@tanstack/react-query';
import { LoaderFunctionArgs } from 'react-router-dom';
import { userQuery } from '~/shared/api/user.queries';
import { checkUserAuthAndTerms } from '~/shared/auth/auth-utils';
import { UserModel } from '~/shared/models/user/UserModel';

export type SettingsPageLoaderData = UserModel;

export const settingsPageLoader =
  (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs) => {
    await checkUserAuthAndTerms(queryClient, request);

    return await queryClient.ensureQueryData(userQuery());
  };

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { IgniteUserQueryKey } from '~/services/queryKeys';
import { patchUser } from '~/shared/api/user.api';
import { userQuery } from '~/shared/api/user.queries';
import { RoleModel } from '~/shared/models/role/RoleModel';
import { UserModel } from '~/shared/models/user/UserModel';
import { User } from '~/types/wavin/ignite/v1/user_pb';
import { assertIsDefined } from '~/types/assert-type';

export function useUser() {
  const { data: user } = useQuery(userQuery());
  return user ?? undefined;
}

export function useUserRoles() {
  const user = useUser();

  return user?.roles ?? [];
}

export function useUserRequiredRole(requiredRole: RoleModel) {
  const userRoles = useUserRoles();

  return userRoles.some((role) => requiredRole.isEqual(role));
}

export const useUpdateUserTermsAndConditionsVersion = ({
  onSuccess,
}: {
  onSuccess?: () => void;
}) => {
  const user = useUser();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [IgniteUserQueryKey.PATCH_USER],
    mutationFn: async ({
      termsAcceptedVersion,
    }: {
      termsAcceptedVersion: string;
    }) => {
      assertIsDefined(user);

      return await patchUser({
        user: {
          name: user.name,
          termsAcceptedVersion,
        },
      });
    },
    onSuccess: (updatedUser) => {
      queryClient.setQueryData(
        userQuery().queryKey,
        new UserModel(updatedUser),
      );
      onSuccess?.();
    },
  });
};

export const useUpdateUserPreferredLanguage = () => {
  const user = useUser();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ languageCode }: { languageCode: string }) => {
      if (!user) return undefined;

      return await patchUser({
        user: {
          name: user.name,
          languageCode,
        },
      });
    },
    onSuccess: (result: User | undefined) => {
      if (result) {
        queryClient.setQueryData(userQuery().queryKey, new UserModel(result));
      }
    },
  });
};

import { QueryClient } from '@tanstack/react-query';
import { LoaderFunctionArgs } from 'react-router-dom';
import { checkUserAuthAndTerms } from '~/shared/auth/auth-utils';
import { listDevicesQuery } from '~/shared/api/listDevices.queries';
import { DevicesListModel } from '~/shared/models/devicesList/DevicesListModel';

export type DevicesListPageLoaderData = DevicesListModel;

export const devicesListPageLoader =
  (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs) => {
    await checkUserAuthAndTerms(queryClient, request);

    return await queryClient.ensureQueryData(listDevicesQuery);
  };

import { timestampFromDate } from '@bufbuild/protobuf/wkt';
import { create } from '@bufbuild/protobuf';
import { formatTimestamp } from '~/shared/utils/formatTimestamp';
import {
  Hex,
  Property,
  ObjectNode,
  DeviceConfigSourceSchema,
  HexSchema,
  PropertySchema,
  ObjectNodeSchema,
} from '~/types/wavin/sentio/v1/device_config_source_pb';
import { ValueModel } from './ValueModel';
import { DeviceConfigSchema } from '~/types/wavin/blaze/v1/device_config_pb';

export type TableProperty = {
  rawOid?: Hex;
  rawVid?: Hex;
  oid?: string;
  vid?: string;
  timestamp: string;
  value?: ValueModel;
  timestampSeconds?: bigint;
};

export type TableObject = {
  oid?: string;
  name?: string;
  deviceType?: string;
  subRows: (TableProperty | TableObject)[];
};

const hexToDisplay = (hex: Hex) =>
  [hex.stringValue, hex.type].filter(Boolean).join(' - ');

const getDeviceType = (row: ObjectNode) =>
  row.deviceType && hexToDisplay(row.deviceType);

const getOID = (row?: ObjectNode) => row?.id?.stringValue;

const getVID = (row: Property) => row.id && hexToDisplay(row.id);

export class BrokerValuesModel {
  readonly data: TableObject;

  constructor(objectTree: ObjectNode) {
    this.data = BrokerValuesModel.createTableData(objectTree);
  }

  static createTableData(node: ObjectNode, parent?: ObjectNode): TableObject;
  static createTableData(node: Property, parent?: ObjectNode): TableProperty;
  static createTableData(
    node: ObjectNode | Property,
    parent?: ObjectNode,
  ): TableObject | TableProperty {
    if (node.$typeName === ObjectNodeSchema.typeName) {
      const objectNode = node as ObjectNode;
      return {
        oid: getOID(objectNode),
        name: objectNode.name,
        deviceType: getDeviceType(objectNode),
        subRows: [
          ...objectNode.children.map((child) =>
            BrokerValuesModel.createTableData(child),
          ),
          ...objectNode.properties.map((child) =>
            BrokerValuesModel.createTableData(child, objectNode),
          ),
        ],
      };
    }

    const propertyNode = node as Property;

    return {
      rawOid: parent?.id,
      rawVid: node.id,
      name: parent?.name,
      oid: getOID(parent),
      vid: getVID(propertyNode),
      deviceType:
        parent && parent.deviceType && hexToDisplay(parent.deviceType),
      timestamp: formatTimestamp(propertyNode.timestamp),
      timestampSeconds: propertyNode.timestamp?.seconds,
      value: new ValueModel(propertyNode.value),
    };
  }

  static createDeviceConfigDelta(newValue: string, property: TableProperty) {
    const timestamp = timestampFromDate(new Date());
    return create(DeviceConfigSchema, {
      timestamp,
      source: {
        case: 'sentioSource',
        value: create(DeviceConfigSourceSchema, {
          changes: [
            create(ObjectNodeSchema, {
              id: create(HexSchema, { value: property.rawOid?.value }),
              properties: [
                create(PropertySchema, {
                  id: create(HexSchema, { value: property.rawVid?.value }),
                  timestamp,
                  value: property.value?.createNewOriginalValue(newValue),
                }),
              ],
            }),
          ],
        }),
      },
    });
  }
}

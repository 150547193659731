import { Device_FirmwareUpdateStatus } from '~/types/wavin/blaze/v1/device_pb';

export class DeviceFirmwareModel {
  readonly availableVersion: string;

  readonly installedVersion: string;

  canUpdate: boolean;

  canMigratePlatform: boolean;

  isUpdating: boolean;

  firmwareStatus: Device_FirmwareUpdateStatus;

  constructor(
    firmwareAvailable: string,
    firmwareInstalled: string,
    firmwareUpdateStatus: Device_FirmwareUpdateStatus,
    isAutomaticReleaseMode: boolean,
  ) {
    this.availableVersion = firmwareAvailable;
    this.installedVersion = firmwareInstalled;
    this.firmwareStatus = firmwareUpdateStatus;
    this.canMigratePlatform =
      DeviceFirmwareModel.canMigratePlatform(firmwareUpdateStatus);
    this.canUpdate = DeviceFirmwareModel.canUpdate({
      firmwareUpdateStatus,
      isAutomaticReleaseMode,
    });
    this.isUpdating = DeviceFirmwareModel.isUpdating(firmwareUpdateStatus);
  }

  updateStatus = ({
    firmwareUpdateStatus,
    isAutomaticReleaseMode,
  }: {
    firmwareUpdateStatus: Device_FirmwareUpdateStatus;
    isAutomaticReleaseMode: boolean;
  }) => {
    this.firmwareStatus = firmwareUpdateStatus;
    this.canUpdate = DeviceFirmwareModel.canUpdate({
      firmwareUpdateStatus,
      isAutomaticReleaseMode,
    });
    this.isUpdating = DeviceFirmwareModel.isUpdating(firmwareUpdateStatus);
  };

  private static canMigratePlatform = (
    firmwareUpdateStatus: Device_FirmwareUpdateStatus,
  ) =>
    firmwareUpdateStatus === Device_FirmwareUpdateStatus.IDLE ||
    firmwareUpdateStatus === Device_FirmwareUpdateStatus.AVAILABLE;

  private static canUpdate = ({
    firmwareUpdateStatus,
    isAutomaticReleaseMode,
  }: {
    firmwareUpdateStatus: Device_FirmwareUpdateStatus;
    isAutomaticReleaseMode: boolean;
  }) =>
    (firmwareUpdateStatus === Device_FirmwareUpdateStatus.AVAILABLE ||
      firmwareUpdateStatus === Device_FirmwareUpdateStatus.UPDATE_FAILED ||
      firmwareUpdateStatus === Device_FirmwareUpdateStatus.UPDATE_REJECTED) &&
    isAutomaticReleaseMode;

  private static isUpdating = (
    firmwareUpdateStatus: Device_FirmwareUpdateStatus,
  ) =>
    firmwareUpdateStatus === Device_FirmwareUpdateStatus.READY_TO_INSTALL ||
    firmwareUpdateStatus === Device_FirmwareUpdateStatus.UPDATING;
}

// @generated by protoc-gen-es v2.0.0 with parameter "target=ts"
// @generated from file wavin/blaze/v1/firmware.proto (package wavin.blaze.v1, syntax proto3)
/* eslint-disable */

import type { GenEnum, GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { enumDesc, fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_buf_validate_validate } from "../../../buf/validate/validate_pb";
import type { Timestamp } from "@bufbuild/protobuf/wkt";
import { file_google_protobuf_timestamp } from "@bufbuild/protobuf/wkt";
import type { User } from "./user_pb";
import { file_wavin_blaze_v1_user } from "./user_pb";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file wavin/blaze/v1/firmware.proto.
 */
export const file_wavin_blaze_v1_firmware: GenFile = /*@__PURE__*/
  fileDesc("Ch13YXZpbi9ibGF6ZS92MS9maXJtd2FyZS5wcm90bxIOd2F2aW4uYmxhemUudjEioQQKCEZpcm13YXJlEiIKBG5hbWUYASABKAlCFLpIEcgBAXIMOgpmaXJtd2FyZXMvEi8KC2NyZWF0ZV90aW1lGAIgASgLMhouZ29vZ2xlLnByb3RvYnVmLlRpbWVzdGFtcBIwCgxwYWNrYWdlX3RpbWUYAyABKAsyGi5nb29nbGUucHJvdG9idWYuVGltZXN0YW1wEjUKDHJlbGVhc2VfdGltZRgEIAEoCzIaLmdvb2dsZS5wcm90b2J1Zi5UaW1lc3RhbXBIAIgBARIPCgd2ZXJzaW9uGAUgASgJEi8KBnN0YXR1cxgGIAEoDjIfLndhdmluLmJsYXplLnYxLkZpcm13YXJlLlN0YXR1cxIpCgt1cGxvYWRlZF9ieRgHIAEoCzIULndhdmluLmJsYXplLnYxLlVzZXISKQoLcmVsZWFzZWRfYnkYCCABKAsyFC53YXZpbi5ibGF6ZS52MS5Vc2VyEhQKB3BhY2thZ2UYCSABKAxIAYgBARISCgV0aXRsZRgKIAEoCUgCiAEBEhoKDXJlbGVhc2Vfbm90ZXMYCyABKAlIA4gBASJACgZTdGF0dXMSFgoSU1RBVFVTX1VOU1BFQ0lGSUVEEAASDQoJU1RBVFVTX0dBEAESDwoLU1RBVFVTX0JFVEEQAkIPCg1fcmVsZWFzZV90aW1lQgoKCF9wYWNrYWdlQggKBl90aXRsZUIQCg5fcmVsZWFzZV9ub3Rlc0J9ChJjb20ud2F2aW4uYmxhemUudjFCDUZpcm13YXJlUHJvdG9QAaICA1dCWKoCDldhdmluLkJsYXplLlYxygIOV2F2aW5cQmxhemVcVjHiAhpXYXZpblxCbGF6ZVxWMVxHUEJNZXRhZGF0YeoCEFdhdmluOjpCbGF6ZTo6VjFiBnByb3RvMw", [file_buf_validate_validate, file_google_protobuf_timestamp, file_wavin_blaze_v1_user]);

/**
 * A firmware package for a device
 *
 * @generated from message wavin.blaze.v1.Firmware
 */
export type Firmware = Message<"wavin.blaze.v1.Firmware"> & {
  /**
   * Firmware resource name, format: firmwares/{firmware}
   *
   * @generated from field: string name = 1;
   */
  name: string;

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 2;
   */
  createTime?: Timestamp;

  /**
   * Time binary package is created
   *
   * @generated from field: google.protobuf.Timestamp package_time = 3;
   */
  packageTime?: Timestamp;

  /**
   * Timestamp when firmware is released GA
   *
   * @generated from field: optional google.protobuf.Timestamp release_time = 4;
   */
  releaseTime?: Timestamp;

  /**
   * @generated from field: string version = 5;
   */
  version: string;

  /**
   * @generated from field: wavin.blaze.v1.Firmware.Status status = 6;
   */
  status: Firmware_Status;

  /**
   * @generated from field: wavin.blaze.v1.User uploaded_by = 7;
   */
  uploadedBy?: User;

  /**
   * @generated from field: wavin.blaze.v1.User released_by = 8;
   */
  releasedBy?: User;

  /**
   * The binary package data
   *
   * @generated from field: optional bytes package = 9;
   */
  package?: Uint8Array;

  /**
   * @generated from field: optional string title = 10;
   */
  title?: string;

  /**
   * @generated from field: optional string release_notes = 11;
   */
  releaseNotes?: string;
};

/**
 * Describes the message wavin.blaze.v1.Firmware.
 * Use `create(FirmwareSchema)` to create a new message.
 */
export const FirmwareSchema: GenMessage<Firmware> = /*@__PURE__*/
  messageDesc(file_wavin_blaze_v1_firmware, 0);

/**
 * @generated from enum wavin.blaze.v1.Firmware.Status
 */
export enum Firmware_Status {
  /**
   * @generated from enum value: STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * General availability
   *
   * @generated from enum value: STATUS_GA = 1;
   */
  GA = 1,

  /**
   * @generated from enum value: STATUS_BETA = 2;
   */
  BETA = 2,
}

/**
 * Describes the enum wavin.blaze.v1.Firmware.Status.
 */
export const Firmware_StatusSchema: GenEnum<Firmware_Status> = /*@__PURE__*/
  enumDesc(file_wavin_blaze_v1_firmware, 0, 0);


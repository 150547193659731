// @generated by protoc-gen-es v2.0.0 with parameter "target=ts"
// @generated from file wavin/blaze/v1/device_updater.proto (package wavin.blaze.v1, syntax proto3)
/* eslint-disable */

import type { GenEnum, GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { enumDesc, fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_buf_validate_validate } from "../../../buf/validate/validate_pb";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file wavin/blaze/v1/device_updater.proto.
 */
export const file_wavin_blaze_v1_device_updater: GenFile = /*@__PURE__*/
  fileDesc("CiN3YXZpbi9ibGF6ZS92MS9kZXZpY2VfdXBkYXRlci5wcm90bxIOd2F2aW4uYmxhemUudjEigQkKDURldmljZVVwZGF0ZXISKgoEbmFtZRgBIAEoCUIcukgZyAEBchQ6CGRldmljZXMvQggvdXBkYXRlchI7Cgpjb21wb25lbnRzGAIgAygLMicud2F2aW4uYmxhemUudjEuRGV2aWNlVXBkYXRlci5Db21wb25lbnQahggKCUNvbXBvbmVudBIMCgRuYW1lGAEgASgJEg0KBXRpdGxlGAIgASgJEhMKC2Rlc2NyaXB0aW9uGAMgASgJEhUKDXNlcmlhbF9udW1iZXIYBCABKAkSGgoSZmlybXdhcmVfaW5zdGFsbGVkGAUgASgJEj4KBnN0YXR1cxgGIAEoDjIuLndhdmluLmJsYXplLnYxLkRldmljZVVwZGF0ZXIuQ29tcG9uZW50LlN0YXR1cxJcChZmaXJtd2FyZV91cGRhdGVfc3RhdHVzGAcgASgOMjwud2F2aW4uYmxhemUudjEuRGV2aWNlVXBkYXRlci5Db21wb25lbnQuRmlybXdhcmVVcGRhdGVTdGF0dXMSXQoWZmlybXdhcmVfY29tcGF0aWJpbGl0eRgIIAEoDjI9LndhdmluLmJsYXplLnYxLkRldmljZVVwZGF0ZXIuQ29tcG9uZW50LkZpcm13YXJlQ29tcGF0aWJpbGl0eRIfChJmaXJtd2FyZV9hdmFpbGFibGUYCSABKAlIAIgBASIvCgZTdGF0dXMSDQoJU1RBVFVTX09LEAASFgoSU1RBVFVTX1VOQVZBSUxBQkxFEAEi/AIKFEZpcm13YXJlVXBkYXRlU3RhdHVzEiYKIkZJUk1XQVJFX1VQREFURV9TVEFUVVNfVU5TUEVDSUZJRUQQABIfChtGSVJNV0FSRV9VUERBVEVfU1RBVFVTX0lETEUQARIkCiBGSVJNV0FSRV9VUERBVEVfU1RBVFVTX0FWQUlMQUJMRRACEjIKLkZJUk1XQVJFX1VQREFURV9TVEFUVVNfQVZBSUxBQkxFX0ZPUkNFRF9VUERBVEUQAxIoCiRGSVJNV0FSRV9VUERBVEVfU1RBVFVTX05PVF9BVkFJTEFCTEUQBBIjCh9GSVJNV0FSRV9VUERBVEVfU1RBVFVTX1VQREFUSU5HEAUSKAokRklSTVdBUkVfVVBEQVRFX1NUQVRVU19VUERBVEVfRkFJTEVEEAYSIgoeRklSTVdBUkVfVVBEQVRFX1NUQVRVU19JR05PUkVEEAcSJAogRklSTVdBUkVfVVBEQVRFX1NUQVRVU19VTkNIRUNLRUQQCCKuAQoVRmlybXdhcmVDb21wYXRpYmlsaXR5EiYKIkZJUk1XQVJFX0NPTVBBVElCSUxJVFlfVU5TUEVDSUZJRUQQABIdChlGSVJNV0FSRV9DT01QQVRJQklMSVRZX09LEAESKgomRklSTVdBUkVfQ09NUEFUSUJJTElUWV9PS19TQU1FX1ZFUlNJT04QAhIiCh5GSVJNV0FSRV9DT01QQVRJQklMSVRZX05PX0ZJTEUQA0IVChNfZmlybXdhcmVfYXZhaWxhYmxlQoIBChJjb20ud2F2aW4uYmxhemUudjFCEkRldmljZVVwZGF0ZXJQcm90b1ABogIDV0JYqgIOV2F2aW4uQmxhemUuVjHKAg5XYXZpblxCbGF6ZVxWMeICGldhdmluXEJsYXplXFYxXEdQQk1ldGFkYXRh6gIQV2F2aW46OkJsYXplOjpWMWIGcHJvdG8z", [file_buf_validate_validate]);

/**
 * The device updater for updating individual components
 *
 * @generated from message wavin.blaze.v1.DeviceUpdater
 */
export type DeviceUpdater = Message<"wavin.blaze.v1.DeviceUpdater"> & {
  /**
   * DeviceUpdater resource name, format: devices/{device}/updater
   *
   * @generated from field: string name = 1;
   */
  name: string;

  /**
   * @generated from field: repeated wavin.blaze.v1.DeviceUpdater.Component components = 2;
   */
  components: DeviceUpdater_Component[];
};

/**
 * Describes the message wavin.blaze.v1.DeviceUpdater.
 * Use `create(DeviceUpdaterSchema)` to create a new message.
 */
export const DeviceUpdaterSchema: GenMessage<DeviceUpdater> = /*@__PURE__*/
  messageDesc(file_wavin_blaze_v1_device_updater, 0);

/**
 * @generated from message wavin.blaze.v1.DeviceUpdater.Component
 */
export type DeviceUpdater_Component = Message<"wavin.blaze.v1.DeviceUpdater.Component"> & {
  /**
   * Component resource name, format: devices/{device}/components/{component}
   *
   * @generated from field: string name = 1;
   */
  name: string;

  /**
   * @generated from field: string title = 2;
   */
  title: string;

  /**
   * @generated from field: string description = 3;
   */
  description: string;

  /**
   * @generated from field: string serial_number = 4;
   */
  serialNumber: string;

  /**
   * @generated from field: string firmware_installed = 5;
   */
  firmwareInstalled: string;

  /**
   * @generated from field: wavin.blaze.v1.DeviceUpdater.Component.Status status = 6;
   */
  status: DeviceUpdater_Component_Status;

  /**
   * @generated from field: wavin.blaze.v1.DeviceUpdater.Component.FirmwareUpdateStatus firmware_update_status = 7;
   */
  firmwareUpdateStatus: DeviceUpdater_Component_FirmwareUpdateStatus;

  /**
   * Only when firmware_name is given for compatibility check
   *
   * @generated from field: wavin.blaze.v1.DeviceUpdater.Component.FirmwareCompatibility firmware_compatibility = 8;
   */
  firmwareCompatibility: DeviceUpdater_Component_FirmwareCompatibility;

  /**
   * Only when firmware_name is given for compatibility check
   *
   * @generated from field: optional string firmware_available = 9;
   */
  firmwareAvailable?: string;
};

/**
 * Describes the message wavin.blaze.v1.DeviceUpdater.Component.
 * Use `create(DeviceUpdater_ComponentSchema)` to create a new message.
 */
export const DeviceUpdater_ComponentSchema: GenMessage<DeviceUpdater_Component> = /*@__PURE__*/
  messageDesc(file_wavin_blaze_v1_device_updater, 0, 0);

/**
 * @generated from enum wavin.blaze.v1.DeviceUpdater.Component.Status
 */
export enum DeviceUpdater_Component_Status {
  /**
   * buf:lint:ignore ENUM_ZERO_VALUE_SUFFIX
   *
   * @generated from enum value: STATUS_OK = 0;
   */
  OK = 0,

  /**
   * @generated from enum value: STATUS_UNAVAILABLE = 1;
   */
  UNAVAILABLE = 1,
}

/**
 * Describes the enum wavin.blaze.v1.DeviceUpdater.Component.Status.
 */
export const DeviceUpdater_Component_StatusSchema: GenEnum<DeviceUpdater_Component_Status> = /*@__PURE__*/
  enumDesc(file_wavin_blaze_v1_device_updater, 0, 0, 0);

/**
 * @generated from enum wavin.blaze.v1.DeviceUpdater.Component.FirmwareUpdateStatus
 */
export enum DeviceUpdater_Component_FirmwareUpdateStatus {
  /**
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_IDLE = 1;
   */
  IDLE = 1,

  /**
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_AVAILABLE = 2;
   */
  AVAILABLE = 2,

  /**
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_AVAILABLE_FORCED_UPDATE = 3;
   */
  AVAILABLE_FORCED_UPDATE = 3,

  /**
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_NOT_AVAILABLE = 4;
   */
  NOT_AVAILABLE = 4,

  /**
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_UPDATING = 5;
   */
  UPDATING = 5,

  /**
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_UPDATE_FAILED = 6;
   */
  UPDATE_FAILED = 6,

  /**
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_IGNORED = 7;
   */
  IGNORED = 7,

  /**
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_UNCHECKED = 8;
   */
  UNCHECKED = 8,
}

/**
 * Describes the enum wavin.blaze.v1.DeviceUpdater.Component.FirmwareUpdateStatus.
 */
export const DeviceUpdater_Component_FirmwareUpdateStatusSchema: GenEnum<DeviceUpdater_Component_FirmwareUpdateStatus> = /*@__PURE__*/
  enumDesc(file_wavin_blaze_v1_device_updater, 0, 0, 1);

/**
 * @generated from enum wavin.blaze.v1.DeviceUpdater.Component.FirmwareCompatibility
 */
export enum DeviceUpdater_Component_FirmwareCompatibility {
  /**
   * @generated from enum value: FIRMWARE_COMPATIBILITY_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * update possible for component, flash to new version
   *
   * @generated from enum value: FIRMWARE_COMPATIBILITY_OK = 1;
   */
  OK = 1,

  /**
   * update possible, re-flash to same version
   *
   * @generated from enum value: FIRMWARE_COMPATIBILITY_OK_SAME_VERSION = 2;
   */
  OK_SAME_VERSION = 2,

  /**
   * no update file for component
   *
   * @generated from enum value: FIRMWARE_COMPATIBILITY_NO_FILE = 3;
   */
  NO_FILE = 3,
}

/**
 * Describes the enum wavin.blaze.v1.DeviceUpdater.Component.FirmwareCompatibility.
 */
export const DeviceUpdater_Component_FirmwareCompatibilitySchema: GenEnum<DeviceUpdater_Component_FirmwareCompatibility> = /*@__PURE__*/
  enumDesc(file_wavin_blaze_v1_device_updater, 0, 0, 2);


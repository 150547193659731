// @generated by protoc-gen-es v2.0.0 with parameter "target=ts"
// @generated from file wavin/sentio/v1/device_config_source.proto (package wavin.sentio.v1, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_buf_validate_validate } from "../../../buf/validate/validate_pb";
import type { Timestamp } from "@bufbuild/protobuf/wkt";
import { file_google_protobuf_timestamp } from "@bufbuild/protobuf/wkt";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file wavin/sentio/v1/device_config_source.proto.
 */
export const file_wavin_sentio_v1_device_config_source: GenFile = /*@__PURE__*/
  fileDesc("Cip3YXZpbi9zZW50aW8vdjEvZGV2aWNlX2NvbmZpZ19zb3VyY2UucHJvdG8SD3dhdmluLnNlbnRpby52MSJ0ChJEZXZpY2VDb25maWdTb3VyY2USMAoLb2JqZWN0X3RyZWUYASABKAsyGy53YXZpbi5zZW50aW8udjEuT2JqZWN0Tm9kZRIsCgdjaGFuZ2VzGAIgAygLMhsud2F2aW4uc2VudGlvLnYxLk9iamVjdE5vZGUinwIKCk9iamVjdE5vZGUSKAoCaWQYASABKAsyFC53YXZpbi5zZW50aW8udjEuSGV4Qga6SAPIAQESIwoFaW5kZXgYAiABKAsyFC53YXZpbi5zZW50aW8udjEuSGV4Ei4KC2RldmljZV90eXBlGAMgASgLMhQud2F2aW4uc2VudGlvLnYxLkhleEgAiAEBEhEKBG5hbWUYBCABKAlIAYgBARI3Cgpwcm9wZXJ0aWVzGAUgAygLMhkud2F2aW4uc2VudGlvLnYxLlByb3BlcnR5Qgi6SAWSAQIIARItCghjaGlsZHJlbhgGIAMoCzIbLndhdmluLnNlbnRpby52MS5PYmplY3ROb2RlQg4KDF9kZXZpY2VfdHlwZUIHCgVfbmFtZSLZBAoIUHJvcGVydHkSKAoCaWQYASABKAsyFC53YXZpbi5zZW50aW8udjEuSGV4Qga6SAPIAQESPgoJdGltZXN0YW1wGAIgASgLMhouZ29vZ2xlLnByb3RvYnVmLlRpbWVzdGFtcEIPukgMyAEBsgEGSgIICjgBEiQKBnZhbF9kMRgFIAEoBUISukgPGg0YfyiA//////////8BSAASJgoGdmFsX2QyGAYgASgFQhS6SBEaDxj//wEogID+////////AUgAEigKBnZhbF9kNBgHIAEoBUIWukgTGhEY/////wcogICAgPj/////AUgAEhoKBnZhbF91MRgIIAEoDUIIukgFKgMY/wFIABIbCgZ2YWxfdTIYCSABKA1CCbpIBioEGP//A0gAEh0KBnZhbF91NBgKIAEoDUILukgIKgYY/////w9IABIcCgh2YWxfdGV4dBgLIAEoCUIIukgFcgMYgAJIABIcCgh2YWxfZGF0YRgMIAEoDEIIukgFegMYgAJIABIrCgt2YWxfZDJfZnAxMBgNIAEoBUIUukgRGg8Y//8BKICA/v///////wFIABIsCgx2YWxfZDJfZnAxMDAYDiABKAVCFLpIERoPGP//ASiAgP7///////8BSAASIAoLdmFsX3UyX2ZwMTAYDyABKA1CCbpIBioEGP//A0gAEiEKDHZhbF91Ml9mcDEwMBgQIAEoDUIJukgGKgQY//8DSAASJwoTdmFsX3NlcnZpY2VfY29tbWFuZBgRIAEoDEIIukgFegMYgARIAEIOCgV2YWx1ZRIFukgCCAEiQAoDSGV4EhUKBXZhbHVlGAEgASgNQga6SAPIAQESFAoMc3RyaW5nX3ZhbHVlGAIgASgJEgwKBHR5cGUYAyABKAlCtAEKE2NvbS53YXZpbi5zZW50aW8udjFCF0RldmljZUNvbmZpZ1NvdXJjZVByb3RvUAFaJndhdmluLmlvL2dlbi9nby93YXZpbi9zZW50aW8vdjE7c2VudGlvogIDV1NYqgIPV2F2aW4uU2VudGlvLlYxygIPV2F2aW5cU2VudGlvXFYx4gIbV2F2aW5cU2VudGlvXFYxXEdQQk1ldGFkYXRh6gIRV2F2aW46OlNlbnRpbzo6VjFiBnByb3RvMw", [file_buf_validate_validate, file_google_protobuf_timestamp]);

/**
 * Sentio specific config source representing the raw object tree
 *
 * @generated from message wavin.sentio.v1.DeviceConfigSource
 */
export type DeviceConfigSource = Message<"wavin.sentio.v1.DeviceConfigSource"> & {
  /**
   * Object tree, traverse children to build tree (only included when allData is true)
   *
   * @generated from field: wavin.sentio.v1.ObjectNode object_tree = 1;
   */
  objectTree?: ObjectNode;

  /**
   * Write only, object changes to apply
   *
   * @generated from field: repeated wavin.sentio.v1.ObjectNode changes = 2;
   */
  changes: ObjectNode[];
};

/**
 * Describes the message wavin.sentio.v1.DeviceConfigSource.
 * Use `create(DeviceConfigSourceSchema)` to create a new message.
 */
export const DeviceConfigSourceSchema: GenMessage<DeviceConfigSource> = /*@__PURE__*/
  messageDesc(file_wavin_sentio_v1_device_config_source, 0);

/**
 * @generated from message wavin.sentio.v1.ObjectNode
 */
export type ObjectNode = Message<"wavin.sentio.v1.ObjectNode"> & {
  /**
   * OID
   *
   * i.e 14680635 "0XE0023B" (no type)
   *
   * @generated from field: wavin.sentio.v1.Hex id = 1;
   */
  id?: Hex;

  /**
   * OIDX
   *
   * i.e. 224 "0xE0" DEVICE_TYPE_CCU208
   *
   * @generated from field: wavin.sentio.v1.Hex index = 2;
   */
  index?: Hex;

  /**
   * DeviceType if object is a device
   *
   * @generated from field: optional wavin.sentio.v1.Hex device_type = 3;
   */
  deviceType?: Hex;

  /**
   * ObjectName
   *
   * @generated from field: optional string name = 4;
   */
  name?: string;

  /**
   * Properties are the key value pairs of the object
   *
   * @generated from field: repeated wavin.sentio.v1.Property properties = 5;
   */
  properties: Property[];

  /**
   * Children
   *
   * @generated from field: repeated wavin.sentio.v1.ObjectNode children = 6;
   */
  children: ObjectNode[];
};

/**
 * Describes the message wavin.sentio.v1.ObjectNode.
 * Use `create(ObjectNodeSchema)` to create a new message.
 */
export const ObjectNodeSchema: GenMessage<ObjectNode> = /*@__PURE__*/
  messageDesc(file_wavin_sentio_v1_device_config_source, 1);

/**
 * @generated from message wavin.sentio.v1.Property
 */
export type Property = Message<"wavin.sentio.v1.Property"> & {
  /**
   * i.e. 32912 "0X8090" VID_OBJECT_NAME
   *
   * @generated from field: wavin.sentio.v1.Hex id = 1;
   */
  id?: Hex;

  /**
   * @generated from field: google.protobuf.Timestamp timestamp = 2;
   */
  timestamp?: Timestamp;

  /**
   * @generated from oneof wavin.sentio.v1.Property.value
   */
  value: {
    /**
     * 1 byte signed integer
     *
     * @generated from field: int32 val_d1 = 5;
     */
    value: number;
    case: "valD1";
  } | {
    /**
     * 2 bytes signed integer
     *
     * @generated from field: int32 val_d2 = 6;
     */
    value: number;
    case: "valD2";
  } | {
    /**
     * 4 bytes signed integer
     *
     * @generated from field: int32 val_d4 = 7;
     */
    value: number;
    case: "valD4";
  } | {
    /**
     * 1 byte unsigned integer
     *
     * @generated from field: uint32 val_u1 = 8;
     */
    value: number;
    case: "valU1";
  } | {
    /**
     * 2 bytes unsigned integer
     *
     * @generated from field: uint32 val_u2 = 9;
     */
    value: number;
    case: "valU2";
  } | {
    /**
     * 4 bytes unsigned integer
     *
     * @generated from field: uint32 val_u4 = 10;
     */
    value: number;
    case: "valU4";
  } | {
    /**
     * max 256 ASCII value
     *
     * @generated from field: string val_text = 11;
     */
    value: string;
    case: "valText";
  } | {
    /**
     * max 256 bytes of data
     *
     * @generated from field: bytes val_data = 12;
     */
    value: Uint8Array;
    case: "valData";
  } | {
    /**
     * 2 bytes signed decimal fixed point number. (value * 10) (-1.1 -> -11)
     *
     * @generated from field: int32 val_d2_fp10 = 13;
     */
    value: number;
    case: "valD2Fp10";
  } | {
    /**
     * 2 bytes signed fixed point number. (value * 100) (-1.11 -> -111)
     *
     * @generated from field: int32 val_d2_fp100 = 14;
     */
    value: number;
    case: "valD2Fp100";
  } | {
    /**
     * 2 bytes unsigned fixed point number. (value * 10) (1.1 -> 11)
     *
     * @generated from field: uint32 val_u2_fp10 = 15;
     */
    value: number;
    case: "valU2Fp10";
  } | {
    /**
     * 2 bytes unsigned fixed point number. (value * 100) (1.11 -> 111)
     *
     * @generated from field: uint32 val_u2_fp100 = 16;
     */
    value: number;
    case: "valU2Fp100";
  } | {
    /**
     * max 512 bytes of data
     *
     * @generated from field: bytes val_service_command = 17;
     */
    value: Uint8Array;
    case: "valServiceCommand";
  } | { case: undefined; value?: undefined };
};

/**
 * Describes the message wavin.sentio.v1.Property.
 * Use `create(PropertySchema)` to create a new message.
 */
export const PropertySchema: GenMessage<Property> = /*@__PURE__*/
  messageDesc(file_wavin_sentio_v1_device_config_source, 2);

/**
 * @generated from message wavin.sentio.v1.Hex
 */
export type Hex = Message<"wavin.sentio.v1.Hex"> & {
  /**
   * value, i.e. 32912
   *
   * @generated from field: uint32 value = 1;
   */
  value: number;

  /**
   * hex value as string, i.e. "0X8090"
   *
   * @generated from field: string string_value = 2;
   */
  stringValue: string;

  /**
   * type, i.e. VID_OBJECT_NAME
   *
   * @generated from field: string type = 3;
   */
  type: string;
};

/**
 * Describes the message wavin.sentio.v1.Hex.
 * Use `create(HexSchema)` to create a new message.
 */
export const HexSchema: GenMessage<Hex> = /*@__PURE__*/
  messageDesc(file_wavin_sentio_v1_device_config_source, 3);

